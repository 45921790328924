@import "variables.scss";

[data-icon]:before {
  font-family: $font-faarrows !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="faa-"]:before,


[class*=" faa-"]:before {
  font-family: $font-faarrows !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.faa-angle-down:before {
  content: "\61";
}
.faa-angle-left:before {
  content: "\62";
}
.faa-angle-right:before {
  content: "\63";
}
.faa-angle-up:before {
  content: "\64";
}
.faa-angle-double-right:before {
  content: "\65";
}
.faa-angle-double-left:before {
  content: "\66";
}
.faa-angle-double-up:before {
  content: "\67";
}
.faa-angle-double-down:before {
  content: "\68";
}