@import "mixins/background-image.scss";
@import "mixins/pseudo.scss";
@import "mixins/transform.scss";
@import "mixins/vertical-center.scss";
@import "mixins/vertical-horizontal-center.scss";
@import "variables/z-index.scss";

$font-size: 1.6rem;

$small-up: 20em;
$small-only: 47.9375em;
//480px
$small-wide-up: 30em;
$small-wide-down: 29.9375em;
$small-wide-only: 47.9375em;
$small-max: 47.9375em;
//768px
$medium-up: 48em;
$medium-only: $medium-up + ' and (max-width: 63.9375em)';
$medium-max: 'screen and (max-width: 63.9375em)';
//1024px
$large-up: 64em;
$large-only: $large-up + ' and (max-width: 74.9375em)';
$large-max: 'screen and (max-width: 74.9375em)';
$xlarge-up: 75em;
$retina: '(min-resolution: 1.3dppx)';
$landscape: 'screen and (orientation: landscape)';
$portrait: 'screen and (orientation: portrait)';

//MEDIA QUERIES

@mixin small-only {
	@media (min-width: $small-up) and (max-width: $small-only) {
		@content;
	}
}

@mixin small-wide-only {
	@media (min-width: $small-wide-up) and (max-width: $small-wide-only) {
		@content;

	}
}

@mixin small-wide-down {
	@media screen and (max-width: $small-wide-down) {
		@content;

	}

}

@mixin small-wide-up {
	@media (min-width: #{$small-wide-up}) {
		@content;
	}
}

@mixin medium-up {
	@media (min-width: #{$medium-up}) {
		@content;
	}
}

@mixin large-up {
	@media (min-width: #{$large-up}) {
		@content;
	}
}

@mixin large-only {
	@media (min-width: $large-up) and (max-width: $xlarge-up) {
		@content;
	}
}

@mixin xlarge-up {
	@media (min-width: #{$xlarge-up}) {
		@content;
	}
}

@mixin rotate-mobile {
	@media (min-height: 340px) and (max-height: 548px) {
		@content
	}
}

@mixin rotate-mobile-smaller {
	@media (min-height: 320px) and (max-height: 339px) {
		@content
	}
}

// easing
// Cubic
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$main-gold: #b3a369;
$dark-gold: #84754e;
$lighter-gray: #f5f5f5;
$light-gray: #e7e7e7;
$gray: #d0d0ce;
$alternative-gray: #d6d6d6;
$dark-gray: #7f7f7f;
$darker-gray: #626262;
$blue-special: #081f2c;
$nav-black: #222223;

$font-bold: neutraface2-bold, sans, sans-serif;

$font-demi: neutraface2-demi, sans-serif;

$font-book: neutraface2-book, sans-serif;
$font-light: neutraface2-light, sans-serif;
$font-joey: dearJoe5, sans-serif;
$font-faarrows: faarrows;
