@import "../../App/variables.scss";

.welcomeToClubName {
  text-align: center;
  @include background-image(cover, center);
  background-image: url("../../assets/tellusmore.jpg");
  color: white;

  .vcenter-container{
  	position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    
    // @include rotate-mobile{
    // 	position: relative;
    // 	left: 0;
    // 	top: 0;
    // 	-webkit-transform: translate(0, 0);
	   //  -moz-transform: translate(0, 0);
	   //  -ms-transform: translate(0, 0);
	   //  -o-transform: translate(0, 0);
	   //  transform: translate(0, 0);
    // }

    // @include rotate-mobile-smaller {
    // 	position: relative;
    // 	left: 0;
    // 	top: 0;
    // 	-webkit-transform: translate(0, 0);
	   //  -moz-transform: translate(0, 0);
	   //  -ms-transform: translate(0, 0);
	   //  -o-transform: translate(0, 0);
	   //  transform: translate(0, 0);
    // }

	}

  #mobileButton{
  	margin-bottom: 20px;
  	max-width: 281px !important;

  	@include small-only {
		margin-top: 75px;
	}

	@include small-wide-only{
  		margin-top: 75px;

  	}

	@include rotate-mobile{
		margin-top: 3%;
	}

	@include rotate-mobile-smaller{
		margin-top: 0;
	}
  }

  .clubname {
	color: $main-gold;
	font-family: $font-demi;
	text-transform: uppercase;
	font-size: 3.1rem;
	margin-top: 30px;

	ins {
	  text-decoration: none !important;

	}

	@include small-wide-only{
  		font-size: 5.5rem;
  		margin-top: 30px;
  	}

	@include rotate-mobile{
		margin-top: 6%;
		font-size: 2.7rem;
		margin-bottom: 0;
	}

	@include rotate-mobile-smaller{
		margin-top: 6%;
		font-size: 2.7rem;
		margin-bottom: 0;
	} 

	@include medium-up{
		font-size: 8.0rem;
		margin-top: 0;
		margin-bottom: 0;		
	}

	@include large-up{
		font-size: 10.0rem;
		margin-top: 0;
		margin-bottom: 10px;		
	}

  }

  .welcome-message {
	margin-top: 75px;
	padding-bottom: 30px;
	font-size: 2.4rem;
	font-family: $font-demi;

	@include small-wide-only{
  		margin-top: 75px;
  		font-size: 2.0rem;
  	} 

	// @include rotate-mobile{
	// 	margin-top: 8%;
	// 	font-size: 2.0rem;
	// }

	// @include rotate-mobile-smaller{
	// 	margin-top: 6%;
	// 	font-size: 2.0rem;
	// }
	
	@include  medium-up {
		margin-top: 75px;
		margin-bottom: 15px;
		font-size: 2.0rem;
	}

	@include  large-up {
		margin-top: 75px;
		margin-bottom: 10px;
		font-size: 2.0rem;
	}

  }

  h4 {
	font-size: 2.4rem;
	margin-top: 4%;
	padding-bottom: 20px;
	font-family: $font-light;

	@include small-wide-only{
  		font-size: 2.7rem;
  		margin-top: 0;
  	}

	// @include rotate-mobile{
	// 	margin-top: 0;
	// 	font-size: 2.0rem;
	// } 

	// @include rotate-mobile-smaller{
	// 	margin-top: 0;
	// 	font-size: 2.0rem;
	// } 
	
	@include  medium-up {
		font-size: 4.0rem;
		margin-bottom: 70px;
	}

	@include  large-up {
		margin-bottom: 10px;
	}

  }

}
