@import "../../App/variables.scss";

.ThankYou {
	text-align: center;
	@include background-image(cover, 0 50px);
	background-image: url("../../assets/login.jpg");
	box-sizing: border-box;
	position: relative;

	@include medium-up {
		@include background-image(cover, 0 83px);

	}

	.vcenter-container {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		padding: 0 5%;
	}

	h1 {
		font-size: 4.5rem;
		color: white;
		font-family: $font-demi;
		text-transform: uppercase;
		margin-top: 0;

		@include small-wide-only {
			font-size: 6.0rem;
		}

		@include medium-up {
			font-size: 8.0rem;
		}

		@include large-up {
			font-size: 10.0rem;
		}

		@include rotate-mobile {
			font-size: 7.0rem;
		}

		@include rotate-mobile-smaller {
			font-size: 7.0rem;
		}

	}

	h3 {
		font-size: 2.7rem;
		font-family: $font-light;
		color: white;

		@include small-wide-only {
			font-size: 3.0rem;
		}

		@include medium-up {
			font-size: 3.5rem;
		}

		@include large-up {
			font-size: 4.0rem;
			margin-top: 0;
		}

		@include rotate-mobile {
			font-size: 3.0rem;
		}

		@include rotate-mobile-smaller {
			font-size: 3.0rem;
		}

	}
	h4 {
		font-size: 1.4rem;
		letter-spacing: 2.5px;
		font-family: $font-bold;
		text-transform: uppercase;
		color: white;
		position: absolute;
		bottom: 100px;
		width: 100%;
		padding: 0 5%;

		@include small-only {
			bottom: 60px;
			font-size: 1.2rem;
		}

		@include medium-up {
			bottom: 100px;
		}

	}
}
