@import "variables.scss";

@import "~lato-webfont/lato.css";

$fa-font-path: "~font-awesome-sass/assets/fonts/font-awesome/";
@import "~font-awesome-sass/assets/stylesheets/font-awesome";

* {
	outline: 0 !important;
}

*:focus {
	outline: 0 !important;
}


body {
	font-variant-numeric: lining-nums;
	font-feature-settings: "lnum" 1;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

.pageOne {
	text-align: center;
	margin: auto;
}

button:not(.btnQuiz):not(.btnRadio):not(.customStyle):not(.wpwl-button) {
	color: white;
	border: none;
	background-color: #9d8e52;
	height: 50px;
	width: 350px;
	border-radius: 0;
	&.disabled {
		background-color: #afab9f !important;
	}
}

.form-control[type="text"],
.form-control[type="email"],
.form-control[type="password"],
.form-control[type="date"] {
	border-radius: 0;
	width: 350px;
	height: 50px;
	margin: auto !important;
}

.input-group {
	margin: auto;
	.required-field-icon {
		background-color: transparent;
		font-size: larger;
		border: none;
		font-weight: 600;
		color: red;
		position: absolute;
		right: 0;
		bottom: 0;
		top: 12px;
	}

}

//BUTTONS

.btn {
	max-width: 100% !important;
}

.button {
	background: $main-gold !important;
	margin: 0 auto;
	height: auto !important;
	text-align: center;
	color: white;
	padding: 22px 0 !important;
	font-family: $font-bold;
	letter-spacing: 0.4rem;
	border-radius: 2px !important;
	max-width: 282px;
	width: 100% !important;
	text-transform: uppercase;
	font-size: 1.8rem;

	@include medium-up {
		font-size: 2.2rem;
		max-width: none;
		width: auto !important;
		padding: 22px !important;
		min-width: 280px;
	}

	&--small {
		font-size: 1.4rem;
		padding: 10px 0 !important;
		display: block;
		position: relative;
		width: 100%;
		text-align: center;
		text-transform: uppercase;
		text-decoration: none;
		max-width: 235px;
		margin: 0 auto 10px auto;
		cursor: pointer;
		border-radius: 2px !important;

		&:hover {
			text-decoration: none;
		}

		&--gray {
			background: $gray;
			color: white !important;

			&:active {
				background: $light-gray;
			}
		}

		&--gold {
			background: $main-gold;
			color: white !important;

			&:active {
				background: rgba(179, 163, 105, 0.75);
			}
		}
	}
}

//FORMS

.line-form {
	input {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 1px solid white;
		background: none;
		color: white !important;
		font-family: $font-demi;
		box-shadow: none !important;
		letter-spacing: 0.3rem;
		border-radius: 0;

		&::-webkit-input-placeholder {
			text-transform: uppercase;
			color: white;
			font-size: 1.3rem;
			letter-spacing: 0.5rem;
		}
	}

	select {
		color: white !important;
		font-family: $font-demi;
		box-shadow: none !important;
		letter-spacing: 0.3rem;
		border-radius: 0;
		background-color: transparent !important;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 1px solid white;
		height: 50px;
		margin-bottom: 0;

		&::-webkit-input-placeholder {
			text-transform: uppercase;
			color: white;
			font-size: 1.3rem;
			letter-spacing: 0.5rem;
		}
	}

	&__select {
		select {
			color: white !important;
			font-family: $font-demi;
			box-shadow: none !important;
			letter-spacing: 0.3rem;
			border-radius: 0;
			background-color: transparent !important;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-bottom: 1px solid white;
			height: 50px;
			margin-bottom: 0;

			&:focus {
				background: transparent !important;
			}

			&::-webkit-input-placeholder {
				text-transform: uppercase;
				color: white;
				font-size: 1.3rem;
				letter-spacing: 0.5rem;
			}
		}
	}
}

.line-form--gold {
	textarea {
		resize: none;
		border-radius: 0;
		min-height: 100px;
	}

	input {
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 1px solid white;
		background: none;
		font-family: $font-demi;
		box-shadow: none !important;
		letter-spacing: 0.3rem;
		border-radius: 0;
		border-bottom: 1px solid $dark-gray;
		color: $main-gold !important;
		width: 100% !important;

		&::-webkit-input-placeholder {
			text-transform: uppercase;
			font-size: 1.3rem;
			letter-spacing: 0.5rem;
			color: $main-gold !important;
		}
	}

	select {
		color: $main-gold !important;
		font-family: $font-demi;
		box-shadow: none !important;
		letter-spacing: 0.3rem;
		border-radius: 0;
		background-color: transparent !important;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 1px solid #7f7f7f;
		height: 50px;
		margin-bottom: 0;

		&::-webkit-input-placeholder {
			text-transform: uppercase;
			color: white;
			font-size: 1.3rem;
			letter-spacing: 0.5rem;
		}
	}

	.checkbox {
		label {
			width: 100%;
			text-transform: capitalize;
		}

		input {
			width: 25px !important;
			margin-left: -25px;
		}
	}

	.radio {
		label {
			width: 100%;
			padding-left: 0 !important;
			text-transform: capitalize;
		}

		input {
			width: 20px !important;
			left: 0;
			margin: 3px 0 0 0 !important;
		}
	}

	&__select {
		select {
			color: $main-gold !important;
			font-family: $font-demi;
			box-shadow: none !important;
			letter-spacing: 0.3rem;
			border-radius: 0;
			background-color: transparent !important;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-bottom: 1px solid #7f7f7f;
			height: 50px;
			margin-bottom: 0;

			&::-webkit-input-placeholder {
				text-transform: uppercase;
				color: white;
				font-size: 1.3rem;
				letter-spacing: 0.5rem;
			}
		}
	}
}

//FONTS
@font-face {
	font-family: "Neutraface2-bold";
	src: url("../assets/fonts/neutraface2text-bold.eot");
	src: url("../assets/fonts/neutraface2text-bold.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/neutraface2text-bold.woff2") format("woff2"),
		url("../assets/fonts/neutraface2text-bold.woff") format("woff");
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Neutraface2-demi";
	src: url("../assets/fonts/neutraface2text-demi.eot");
	src: url("../assets/fonts/neutraface2text-demi.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/neutraface2text-demi.woff2") format("woff2"),
		url("../assets/fonts/neutraface2text-demi.woff") format("woff");
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Neutraface2-book";
	src: url("../assets/fonts/neutraface2text-book.eot");
	src: url("../assets/fonts/neutraface2text-book.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/neutraface2text-book.woff2") format("woff2"),
		url("../assets/fonts/neutraface2text-book.woff") format("woff");
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "Neutraface2-light";
	src: url("../assets/fonts/neutraface2text-light.eot");
	src: url("../assets/fonts/neutraface2text-light.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/neutraface2text-light.woff2") format("woff2"),
		url("../assets/fonts/neutraface2text-light.woff") format("woff");
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "dearJoe5";
	src: url("../assets/fonts/dj5.eot");
	src: url("../assets/fonts/dj5.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/dj5.woff2") format("woff2"),
		url("../assets/fonts/dj5.woff") format("woff");
	font-style: normal;
	font-stretch: normal;
}

@font-face {
	font-family: "FAArrows";
	src: url("../assets/fonts/faarrows/faarrows.eot");
	src: url("../assets/fonts/faarrows/faarrows.eot?#iefix")
			format("embedded-opentype"),
		url("../assets/fonts/faarrows/faarrows.woff") format("woff");
	font-style: normal;
	font-stretch: normal;
}

//TEXT

html {
	font-size: 62.5%;
}

.heading-md {
	font-size: 2.2rem;
	text-transform: uppercase;
	font-family: $font-bold;
	letter-spacing: 0.5rem;

	@include small-wide-up {
		font-size: 3rem;
	}
}

.text-white {
	color: white;
}

.text-gold {
	color: $main-gold;
}

.text-black {
	color: black;
}

.text-bold {
	font-family: $font-bold;
}

.text-center {
	text-align: center;
}

//BLOCKs

.vcenter-container {
	display: block;
	position: relative;

	@include large-up {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		@include transform(translate(-50%, -50%));
	}
}

.section {
	padding-top: 100px;
}

/***MODALS***/
.modal-dialog {
	margin: 250px auto 0 auto;
	@include vertical-horizontal-center;
	position: absolute;
}

.input-group__phonePrefix {
	> * {
		display: inline-block;

		&:first-child {
			margin-top: -58px;
			margin-right: 15px;
			width: 90px;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-bottom: 1px solid white;

			button {
				border: none;
				background: none;
				line-height: 1.4rem;
				box-sizing: border-box;
				padding-top: 16px;
				padding-left: 25px;
				padding-right: 25px;
			}
		}
		&:nth-child(2) {
			width: calc(100% - 105px);
		}
	}
}
