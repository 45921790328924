@import "../../../../../App/variables.scss";

.textarea-field {
width: 100%;
color: #b3a369 !important;
border: 1px solid #7f7f7f;
font-size: 1.5rem;
font-family: $font-demi;
font-weight: 600;
letter-spacing: 0.3rem;
padding: 7px;
}