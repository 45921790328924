@mixin vertical-center() {
	top: 50%!important;
	-webkit-transform: translateY(-50%)!important;
	-moz-transform: translateY(-50%)!important;
	-ms-transform: translateY(-50%)!important;
	-o-transform: translateY(-50%)!important;
	transform: translateY(-50%)!important;
}

@mixin horizontal-center() {
	left: 50%!important;
	-webkit-transform: translateX(-50%)!important;
	-moz-transform: translateX(-50%)!important;
	-ms-transform: translateX(-50%)!important;
	-o-transform: translateX(-50%)!important;
	transform: translateX(-50%)!important;
}
